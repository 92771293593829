export const SeoContent = {
    baseSeo: {
        title: "Shinfalls Commodities"
    },
    monthlySeo: {
        title: "Monthly Newsletter"
    },
    marketingSeo: {
        title: "Marketing Presentations"
    },
    offeringSeo: {
        title: "Marketing Presentations"
    },
    dueSeo: {
        title: "Due Diligence Docs"
    },
    fundSeo: {
        title: "Fund Constitutional Docs"
    },
    trashSeo: {
        title: "Trash Bin"
    },

    profileSeo: {
        title: "Profile"
    },
    userManage: {
        title: "User Manage"
    },
    loginSeo: {
        title: "Sign In"
    },

    errorSeo: {
        title: "Error 404"
    }
};
