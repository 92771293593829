import React, { useContext } from "react";

export const UserCard = ({ image, name, userName }: { image?: string; name: string; userName?: string }) => {
    return (
        <div className=" flex items-center gap-x-2">
            {image && (
                <div className="w-[4rem] h-[4rem] rounded-full overflow-hidden">
                    <img src={image} alt="person" className="w-full h-full object-cover" />
                </div>
            )}
            <div className="w-[10rem]">
                <h1 className="font-semibold text-base capitalize">{name}</h1>
                <h1 className="font-medium text-sm">{userName}</h1>
            </div>
        </div>
    );
};
