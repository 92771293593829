import React, { ChangeEvent } from "react";
import { CiImageOn } from "react-icons/ci";
import { FaTrash } from "react-icons/fa";
import { convertImage } from "../../../utils/imageResize";
import { toast } from "react-toastify";

const ImageUpload = ({
    image,
    setImage,
    fileInputRef,
    loading,
    setLoading
}: {
    image: string | null;
    setImage: (image: string | null) => void;
    fileInputRef: React.RefObject<HTMLInputElement>;
    loading: boolean;
    setLoading: (isLoading: boolean) => void;
}) => {
    const handleButtonClick = () => {
        // Trigger the file input click event
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
        // Handle the selected file here
        if (event.target.files && event.target.files.length > 0) {
            const size = event.target.files[0].size;
            if (size < 5000000) {
                const newImage = await convertImage({ image: event.target.files[0], setLoading });
                setImage(newImage);
            } else {
                toast.error("Max image limit 5MB!", {
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light"
                });
            }
        }
    };

    return (
        <div className="h-[15rem] border-2 flex justify-center items-center relative overflow-hidden">
            {image && !loading && <img src={image} alt="" className="absolute top-0 w-full h-full object-cover" />}
            {loading && (
                <div className="w-full h-full absolute top-0 flex justify-center items-center">
                    <h2>loading....</h2>
                </div>
            )}
            {!loading && (
                <button
                    onClick={handleButtonClick}
                    type="button"
                    className={`p-2 text-black  cursor-pointer  flex justify-center items-center  absolute top-0 ${
                        image
                            ? " bg-primary text-4xl right-0 text-white"
                            : " bg-primary/10  w-full h-full text-7xl dark:text-white"
                    } duration-300 `}
                >
                    <CiImageOn />
                </button>
            )}
            {image && (
                <button
                    onClick={() => setImage(null)}
                    type="button"
                    className="absolute left-0 top-0 p-4 bg-error text-white"
                >
                    <FaTrash />
                </button>
            )}
            <input type="file" ref={fileInputRef} style={{ display: "none" }} onChange={handleFileChange} />
        </div>
    );
};

export default ImageUpload;
