import React from "react";
import HelmetConfiguration from "../../utils/helmetConfiguration";
import { SeoContent } from "../../content/seoContent";
import useFetch from "../../hooks/useFetch";
import Loading from "../../components/Common/Loading/Loading";
import FileManageTable from "../../components/FileManageTable/FileManageTable";

const TrashBinPage = () => {
    const [file, loading, refetch] = useFetch({
        api: "file/trash"
    });

    if (loading) {
        return <Loading />;
    }

    return (
        <section>
            <HelmetConfiguration seoData={SeoContent["trashSeo"] || SeoContent.baseSeo} />
            <FileManageTable button file={file} refetch={refetch} />
        </section>
    );
};

export default TrashBinPage;
