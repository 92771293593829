import React, { useState } from "react";

const CategorySelect = ({
    title,
    select,
    update,
    categories,
    multiple
}: {
    title: string;
    select: string[];
    update: React.Dispatch<React.SetStateAction<string[]>>;
    categories: string[];
    multiple?: boolean;
}) => {
    const handleCategoryChange = (category: string) => {
        if (!multiple) {
            update((prevCategories) => {
                const isSelected = prevCategories.includes(category);
                if (isSelected) {
                    return prevCategories.filter((c) => c !== category);
                } else {
                    return [category];
                }
            });
        } else {
            update((prevCategories) => {
                const isSelected = prevCategories.includes(category);
                if (isSelected) {
                    return prevCategories.filter((c) => c !== category);
                } else {
                    return [...prevCategories, category];
                }
            });
        }
    };

    return (
        <div>
            <div>
                <fieldset
                    className={`border-2 pl-3 rounded overflow-hidden relative dark:border-primaryBG/80 border-secondary/50 } `}
                >
                    <legend className={`px-2  text-base font-medium  text-primary `}>{title}</legend>
                    <div
                        className={`grid ${
                            categories.length > 5 ? "grid-cols-2 md:grid-cols-3 lg:grid-cols-5" : "grid-cols-2"
                        }  gap-2 py-2 pb-3 pr-3`}
                    >
                        {categories.map((category) => (
                            <div
                                key={category}
                                className={`border rounded-md w-full ${
                                    select.includes(category) ? "bg-primary border-primary text-white" : ""
                                } cursor-pointer`}
                            >
                                <label className="flex items-center capitalize justify-center p-2 cursor-pointer text-center">
                                    {!multiple ? (
                                        <input
                                            type="checkbox"
                                            value={category}
                                            onChange={() => update([category])}
                                            className="hidden cursor-pointer "
                                        />
                                    ) : (
                                        <input
                                            type="checkbox"
                                            value={category}
                                            checked={select.includes(category)}
                                            onChange={() => handleCategoryChange(category)}
                                            className="hidden cursor-pointer "
                                        />
                                    )}

                                    {category === "user" ? "investor" : category}
                                </label>
                            </div>
                        ))}
                    </div>
                </fieldset>
            </div>
        </div>
    );
};

export default CategorySelect;
