import React from "react";
import General from "../../components/Profile/General/General";
import HelmetConfiguration from "../../utils/helmetConfiguration";
import { SeoContent } from "../../content/seoContent";

const ProfilePage = () => {
    return (
        <section>
            <HelmetConfiguration seoData={SeoContent["profileSeo"] || SeoContent.baseSeo} />
            <div className="pt-10">
                <div className="px-4">
                    <General />
                </div>
            </div>
        </section>
    );
};

export default ProfilePage;
