import React from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

const Pagination = ({
    itemCount,
    page,
    setPage,
    dataLength
}: {
    itemCount: number;
    page: number;
    setPage: React.Dispatch<number>;
    dataLength: number;
}) => {
    return (
        <div className="flex gap-x-4 justify-end py-4 lg:pr-2 border-t-2 mt-4  w-full bg-white dark:bg-darkSecondary">
            <div className="flex items-center lg:gap-x-4">
                <button
                    onClick={() => page > 0 && setPage(page - 1)}
                    className={`text-2xl flex justify-center items-center hover:bg-primary/10 px-2 p-2 rounded-lg ${
                        page === 0 && "cursor-not-allowed hidden"
                    }`}
                    disabled={page === 0}
                >
                    <BiChevronLeft />
                </button>
                <div className="bg-primary/10 rounded-lg p-2 px-4 font-bold">{page + 1}</div>
                <button
                    onClick={() => setPage(page + 1)}
                    className={`text-2xl flex justify-center items-center hover:bg-primary/10 px-2 p-2 rounded-lg ${
                        page + 1 >= dataLength / itemCount && "cursor-not-allowed hidden"
                    }`}
                    disabled={page + 1 > dataLength / itemCount}
                >
                    <BiChevronRight />
                </button>
            </div>
        </div>
    );
};

export default Pagination;
