import React, { useContext } from "react";
import { DARKMODE_CONTEXT } from "../../../provider/DarkModeProvider";
const Loading = ({ title }: { title?: boolean }) => {
    const { darkMode } = useContext(DARKMODE_CONTEXT) || {};
    return (
        <div
            className={`flex  min-h-[100vh]   w-full flex-col items-center justify-center gap-y-3 overflow-y-auto   p-6 ${
                darkMode ? "backgroundLinearDark" : "backgroundLinear"
            } `}
        >
            <div className="flex gap-x-6">
                <span
                    className={`loading-xs h-2  rounded-full border-2 ${
                        darkMode ? "border-white bg-white" : "border-black bg-black"
                    }`}
                ></span>
                <span
                    className={`loading-sm  h-[.65rem]  rounded-full border-2 ${
                        darkMode ? "border-white bg-white" : "border-black bg-black"
                    }`}
                ></span>
                <span
                    className={`loading-md h-[.75rem] rounded-full border-2 ${
                        darkMode ? "border-white bg-white" : "border-black bg-black"
                    }`}
                ></span>
                <span
                    className={`loading-lg  h-[.85rem] rounded-full border-2 ${
                        darkMode ? "border-white bg-white" : "border-black bg-black"
                    }`}
                ></span>
            </div>
            {title && (
                <div>
                    <h2 className="font-semibold pt-2">Processing </h2>
                </div>
            )}
        </div>
    );
};

export default Loading;
