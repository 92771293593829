import React, { useContext, useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import Table from "../Common/Table/Table";
import { DateComponent } from "../Common/Table/UtilityComponent";
import { FileType } from "../../type/FileType";
import { TableData } from "../../type/TableDataType";
import TableLayer from "../Common/TableLayer/TableLayer";
import { DATA_CONTEXT } from "../../provider/DataProvider";
interface FileManageTableProps {
    file: any;
    refetch: any;
    button?: boolean;
}

const FileManageTable: React.FC<FileManageTableProps> = ({ file, refetch, button }) => {
    const { userDataMange } = useContext(DATA_CONTEXT) || {};
    const userRole = userDataMange.user.role;
    const [filterData, setFilterData] = useState(file);
    useEffect(() => {
        setFilterData(file);
    }, [file]);

    const handleSearch = (e: any) => {
        const input = e.target.value.toLowerCase();
        if (input) {
            setFilterData(
                (file as any).filter((item: any) => {
                    const lowercaseTitle = item.fileName.toLowerCase();
                    return lowercaseTitle.includes(input);
                })
            );
        } else {
            setFilterData(file);
        }
    };

    const tableBody = (filterData as any as FileType[]).map((item, index) => {
        return {
            id: item._id,
            index: <h1 className="font-semibold">{index + 1}.</h1>,
            fileName: <h1 className="font-semibold w-64">{item.fileName}</h1>,
            date: <DateComponent date={item.createdAt} />,
            view: (
                <div className="ml-6  p-2 px-4 rounded-md w-fit font-bold text-black dark:text-white">
                    {item.download.length}
                </div>
            ),
            fileUrl: item.fileUrl
        };
    });

    const tableData = {
        columnItems: ["", "Presentations", "Upload Date", "No. of Download", , ""],
        tableBody: tableBody,
        refetch: refetch,
        updateFilter: handleSearch
    };

    return (
        <TableLayer>
            <section className="container mx-auto py-10 ">
                {userRole === "admin" && (
                    <div className=" flex justify-end px-4">
                        <label
                            className="bg-primary  text-white px-4 py-2 rounded-md flex items-center gap-x-2 cursor-pointer duration-300 hover:bg-dark/80 "
                            htmlFor="fileUpload"
                        >
                            <FaPlus /> Upload Doc
                        </label>
                    </div>
                )}
                <Table action={!button} button={button} tableData={tableData as unknown as TableData} />
            </section>
        </TableLayer>
    );
};

export default FileManageTable;
