import React, { useState, useContext } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const CustomInputField = ({
    name,
    title,
    type,
    required,
    min,
    max,
    value,
    disabled,
    textArea,
    pattern
}: {
    name: string;
    title: string;
    type: string;
    required?: boolean;
    min?: number;
    max?: number;
    value?: string | number;
    disabled?: boolean;
    textArea?: boolean;
    pattern?: string;
}) => {
    const [hidden, setHidden] = useState(true);
    const handleType = () => {
        setHidden(!hidden);
    };
    return (
        <div className="w-full h-full">
            <fieldset
                className={`border pl-3 rounded overflow-hidden relative h-full ${
                    disabled ? "border-primary " : "dark:border-primaryBG/80 border-secondary/50"
                } `}
            >
                <legend className={`px-2  text-base font-medium  ${disabled ? "!text-primary  " : "text-primary "}`}>
                    {title}
                </legend>

                {textArea ? (
                    <textarea
                        rows={4}
                        name={name}
                        className="w-full outline-none p-2 pb-3 bg-inherit text-dark/80 dark:text-white/80"
                        required={required}
                        defaultValue={value}
                        disabled={disabled}
                    />
                ) : (
                    <input
                        type={hidden ? type : "text"}
                        name={name}
                        className="w-full outline-none  p-2 pb-3 bg-inherit text-dark/80 dark:text-white/80"
                        required={required}
                        min={min}
                        max={max}
                        defaultValue={value}
                        disabled={disabled}
                        pattern={pattern}
                    />
                )}
                {type === "password" && (
                    <button
                        type="button"
                        onClick={handleType}
                        className={`absolute right-4 top-1 text-2xl dark:text-white text-secondary  `}
                    >
                        {hidden ? <AiFillEyeInvisible /> : <AiFillEye />}
                    </button>
                )}
            </fieldset>
        </div>
    );
};

export default CustomInputField;
