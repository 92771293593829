import React, { useState, useContext } from "react";
import { AiOutlineMail } from "react-icons/ai";
import { FaLock } from "react-icons/fa";

const LoginInput = ({
    name,
    title,
    type,
    required
}: {
    name: string;
    title: string;
    type: string;
    required?: boolean;
}) => {
    return (
        <div className="w-full h-full bg-white flex items-center">
            <span className="text-gray-400 text-2xl pl-3">{type === "email" ? <AiOutlineMail /> : <FaLock />}</span>
            <input
                type={type}
                name={name}
                placeholder={title}
                className="w-full outline-none  p-4  bg-inherit text-dark/80 bg-white"
                required={required}
            />
        </div>
    );
};

export default LoginInput;
