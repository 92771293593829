import React, { useState } from "react";

const InvestorSelect = ({
    title,
    select,
    update,
    investors,
    multiple
}: {
    title: string;
    select: string[];
    update: React.Dispatch<React.SetStateAction<string[]>>;
    investors: { email: string; id: string }[];
    multiple?: boolean;
}) => {
    const handleCategoryChange = (investor: string) => {
        if (investor === "all") {
            update((prev) => {
                const isSelected = prev.includes(investor);
                if (isSelected) {
                    return prev.filter((c) => c !== investor);
                } else {
                    return ["all"];
                }
            });
        } else {
            update((prev) => {
                const isSelected = prev.includes(investor);
                if (isSelected) {
                    return prev.filter((c) => c !== investor && c !== "all");
                } else {
                    return [...prev.filter((c) => c !== "all"), investor];
                }
            });
        }
    };

    return (
        <div>
            <div>
                <fieldset
                    className={`border-2 pl-3 rounded overflow-hidden relative dark:border-primaryBG/80 border-secondary/50 } `}
                >
                    <legend className={`px-2  text-base font-medium  text-primary `}>{title}</legend>
                    <div className={`grid "grid-cols-2 md:grid-cols-3   gap-2 py-2 pb-3 pr-3`}>
                        <div
                            className={`border rounded-md w-full col-span-3 mb-2 ${
                                select.includes("all") ? "bg-primary border-primary text-white" : ""
                            } cursor-pointer`}
                        >
                            <label className="flex items-center capitalize justify-center p-2 cursor-pointer text-center ">
                                <input
                                    type="checkbox"
                                    value="all"
                                    checked={select.includes("all")}
                                    onChange={() => handleCategoryChange("all")}
                                    className="hidden cursor-pointer "
                                />
                                All Investor
                            </label>
                        </div>
                        <div className="col-span-3 border border-primary/60 mb-2"></div>

                        {investors.map((item, index) => (
                            <div
                                key={index}
                                className={`border rounded-md w-full ${
                                    select.includes(item.id) ? "bg-primary border-primary text-white" : ""
                                } cursor-pointer`}
                            >
                                <label className="flex items-center  justify-center p-2 cursor-pointer text-center break-all">
                                    <input
                                        type="checkbox"
                                        value={item.id}
                                        checked={select.includes(item.id)}
                                        onChange={() => handleCategoryChange(item.id)}
                                        className="hidden cursor-pointer "
                                    />

                                    {item.email}
                                </label>
                            </div>
                        ))}
                    </div>
                </fieldset>
            </div>
        </div>
    );
};

export default InvestorSelect;
