import { toast } from "react-toastify";
import { getToken } from "../utils/token";
interface Params {
    endPoint: string;
    id: string;
    refetch?: () => void;
    message: string;
}

export const Delete = ({ endPoint, id, refetch, message }: Params): void => {
    const headers: Record<string, string> = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken()
    };

    fetch(`${process.env.REACT_APP_PUBLIC_SERVER}/${endPoint}/${id}`, {
        method: "DELETE",
        headers: headers
    })
        .then((response) => {
            if (!response.ok) {
                throw new Error(response.status.toString());
            }
            return response.json();
        })
        .then(() => {
            toast.info(message, {
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
        })
        .catch((error) => {
            toast.error(error.message, {
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
        })
        .finally(() => {
            if (refetch) {
                refetch();
            }
        });
};
