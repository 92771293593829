import React, { useContext } from "react";
import { statusColorCheck } from "../../../utils/statusColor";
import { dateFormatter } from "../../../utils/dateFormat";
import { calenderSVG } from "../../../design/svgIcons";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import { FaRegCopy } from "react-icons/fa";
import { toast } from "react-toastify";
import { DARKMODE_CONTEXT } from "../../../provider/DarkModeProvider";

export const ArticleCard = ({
    image,
    name,
    email,
    phone
}: {
    image?: string;
    name: string;
    email?: string;
    phone?: string;
}) => {
    const { darkMode } = useContext(DARKMODE_CONTEXT) || {};

    return (
        <div className=" flex items-start gap-x-2">
            {image && (
                <div className="w-[10rem] h-20 rounded-md overflow-hidden">
                    <img src={image} alt="person" className="w-full h-full object-cover" />
                </div>
            )}
            <div className="w-[20rem]">
                <h1 className="font-medium text-sm">{name}</h1>
            </div>
        </div>
    );
};

export const DateComponent = ({ date }: { date: string }) => {
    const timeDifference = Math.floor((new Date().getTime() - new Date(date).getTime()) / (1000 * 60 * 60 * 24));

    return (
        <div className="!min-w-[10rem]">
            <div className="flex items-start gap-x-2 ">
                <span>{calenderSVG}</span>
                <div>
                    <span className="text-sm">{dateFormatter(date)}</span>
                    <div className="text-xs ">
                        <span>{`${timeDifference} days ago`}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const CategoryGroup = ({ tags }: { tags: string[] }) => {
    return (
        <div className="grid grid-cols-3 gap-2 w-[20rem]">
            {tags.map((item, index) => (
                <div
                    className="bg-primary/80 w-fit px-2 py-1 rounded-md text-xs font-medium text-white capitalize"
                    key={index}
                >
                    {item}
                </div>
            ))}
        </div>
    );
};
