import React, { useContext, useRef, useState } from "react";
import CustomInputField from "../../Common/CustomInputField/CustomInputField";
import { DATA_CONTEXT } from "../../../provider/DataProvider";
import { update } from "../../../hooks/update";

const ProfileForm = () => {
    const [editEnable, setEditEnable] = useState(false);
    const { userDataMange } = useContext(DATA_CONTEXT) || {};

    const formRef = useRef<any | null>(null);
    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const form = formRef.current;
        if (form) {
            const data = {
                name: form.name.value,
                password: form.password.value
            };
            update({
                endPoint: "user",
                id: userDataMange?.user?.userId,
                data: data,
                refetch: [userDataMange?.userRefetch],
                message: "User Information Updated"
            });

            setEditEnable(false);
        }
    };
    return (
        <div className="shadow-lg flex flex-col  gap-y-4 justify-center items-center p-4 rounded-md bg-white dark:bg-darkSecondary py-10 col-span-2 w-full">
            <form ref={formRef} onSubmit={handleSubmit} className="grid grid-cols-1  lg:grid-cols-2 gap-4 w-full">
                <CustomInputField
                    name="name"
                    title="Name"
                    type="text"
                    value={userDataMange.user?.name}
                    required
                    disabled={!editEnable}
                />
                <CustomInputField
                    name="email"
                    title="User Email"
                    type="text"
                    value={userDataMange.user?.email}
                    required
                    disabled={true}
                />

                {editEnable && <CustomInputField name="password" title="Password" type="password" required />}

                <div className="lg:col-span-2 flex justify-end gap-x-3">
                    <button
                        type="button"
                        onClick={() => setEditEnable(false)}
                        className={`${
                            !editEnable && "hidden"
                        } px-4 py-2 hover:bg-error/60 duration-300 bg-error text-white rounded-lg`}
                    >
                        Cancel
                    </button>
                    <button
                        type="submit"
                        className={`${
                            !editEnable && "hidden"
                        } px-4 py-2 hover:bg-primary/60 duration-300 bg-primary text-white rounded-lg`}
                    >
                        Save Change
                    </button>

                    <button
                        type="button"
                        onClick={() => setEditEnable(true)}
                        className={`${
                            editEnable && "hidden"
                        } px-4 py-2 hover:bg-primary/60 duration-300 bg-primary text-white rounded-lg`}
                    >
                        Edit
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ProfileForm;
