import React, { useContext, useRef, useState } from "react";
import { DATA_CONTEXT } from "../../../provider/DataProvider";
import { update } from "../../../hooks/update";
import { convertImage } from "../../../utils/imageResize";

const ProfileImage = () => {
    const { userDataMange } = useContext(DATA_CONTEXT) || {};
    const [loading, setLoading] = useState(false);
    const { user } = userDataMange;

    const fileInputRef = useRef<HTMLInputElement | null>(null);

    // Function to trigger file input when the label is clicked
    const handleLabelClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    // Function to handle file input change
    const handleFileChange = async (e: any) => {
        const selectedFile = e.target.files[0];

        const imgUrl = await convertImage({ image: selectedFile, setLoading: setLoading });

        update({
            endPoint: "user/list",
            id: userDataMange?.user?.id,
            data: { imgURL: imgUrl },
            refetch: [userDataMange?.userRefetch],
            message: "User Image Updated"
        });
    };

    return (
        <div className="shadow-lg flex flex-col  gap-y-4 justify-center items-center p-4 rounded-2xl bg-white dark:bg-darkSecondary py-10 w-full">
            <div className="flex justify-center items-center overflow-hidden w-40 h-40">
                <div
                    className="text-7xl w-40 h-40 border-2 flex justify-center items-center  rounded-full object-cover font-bold cursor-pointer relative overflow-hidden profileImage uppercase"
                    onClick={handleLabelClick}
                >
                    <img src={user.image} alt="" className="h-full object-cover" />
                    {loading && (
                        <div className="bg-dark/50 h-full w-full absolute top-0 flex justify-center items-center">
                            <h1 className="text-xs text-white font-bold">loading...</h1>
                        </div>
                    )}
                    {!loading && (
                        <span className="text-base text-black absolute bottom-0 capitalize bg-gray-200/95 dark:bg-dark/95 dark:text-white w-full text-center h-20 pt-5 duration-300">
                            upload image
                        </span>
                    )}
                </div>
            </div>
            <div className="w-[50%] mx-auto">
                <p className="text-center text-sm">Allowed *.jpeg, *.jpg, *.png, *.gif max size of 3.1 MB</p>
                <label className="cursor-pointer bg-error">
                    <input
                        type="file"
                        accept=".jpeg, .jpg, .png, .gif"
                        onChange={handleFileChange}
                        ref={fileInputRef}
                        style={{ display: "none" }}
                    />
                </label>
            </div>
        </div>
    );
};

export default ProfileImage;
