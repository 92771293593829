import React, { useContext } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { IoIosCloudDownload } from "react-icons/io";
import { BsThreeDotsVertical } from "react-icons/bs";
import { HiPencilAlt } from "react-icons/hi";
import { BiTrashAlt } from "react-icons/bi";
import Swal from "sweetalert2";
import { Delete } from "../../../hooks/delete";
import { DATA_CONTEXT } from "../../../provider/DataProvider";
import { getToken } from "../../../utils/token";

const ActionMenu = ({
    fileUrl,
    id,
    user,
    refetch,
    lastItem,
    edit
}: {
    fileUrl: string;
    id: string;
    user?: boolean;
    refetch: () => void;
    lastItem: boolean;
    edit?: boolean;
}) => {
    const { userDataMange } = useContext(DATA_CONTEXT) || {};
    const userRole = userDataMange.user.role;
    const handleDownload = () => {
        const fileName = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
        const fetchData = async () => {
            try {
                const response = await fetch(fileUrl, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + getToken()
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const blob = await response.blob();

                // Create a download link
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = fileName; // You can set the desired filename here
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error("Error downloading PDF:", error);
            }
        };

        fetchData();
    };

    const handleDelete = () => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#00A76F",
            cancelButtonColor: "#D32F2F",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                Delete({
                    endPoint: user ? "user/list" : "file",
                    id: id,
                    refetch: refetch,
                    message: user ? "User Deleted!" : "File Deleted!"
                });
                Swal.fire("Deleted!", user ? "User has been deleted!" : "Your file has been deleted.", "success");
            }
        });
    };

    return (
        <div className={`dropdown ${lastItem ? "dropdown-top" : ""}  dropdown-end text-base font-semibold`}>
            <label
                tabIndex={0}
                className="btn bg-inherit  hover:bg-primary/10 dark:hover:bg-primary/30 border-0 duration-300 dark:text-white "
            >
                <BsThreeDotsVertical />
            </label>
            <ul
                tabIndex={0}
                className="dropdown-content z-[1] bg-gray-100 menu p-2 shadow  rounded-box w-52  dark:bg-dark"
            >
                <li className="dark:hover:bg-primary/20 overflow-hidden dark:hover:text-white dark:hover:rounded-lg ">
                    {user ? (
                        <label htmlFor={id + "view"} className="dark:!text-white active:!bg-success">
                            <span className="text-lg ">
                                <AiOutlineEye />
                            </span>
                            Preview
                        </label>
                    ) : (
                        <button onClick={handleDownload} className="dark:!text-white active:!bg-success">
                            <span className="text-lg ">
                                <IoIosCloudDownload />
                            </span>
                            Download
                        </button>
                    )}
                </li>
                {userRole !== "user" && edit && (
                    <li className="dark:hover:bg-primary/20 overflow-hidden dark:hover:text-white dark:hover:rounded-lg">
                        <label htmlFor={id + "update"} className="dark:!text-white active:!bg-success">
                            <span className="text-lg">
                                <HiPencilAlt />
                            </span>
                            Update
                        </label>
                    </li>
                )}
                {userRole === "admin" && (
                    <li className="dark:hover:bg-primary/20 overflow-hidden dark:hover:text-white dark:hover:rounded-lg">
                        <button type="button" onClick={handleDelete} className="dark:!text-white active:!bg-success">
                            <span className="text-lg">
                                <BiTrashAlt />
                            </span>
                            Delete
                        </button>
                    </li>
                )}
            </ul>
        </div>
    );
};

export default ActionMenu;
