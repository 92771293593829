import React, { useContext, useState } from "react";
import HelmetConfiguration from "../../utils/helmetConfiguration";

import { SeoContent } from "../../content/seoContent";
import useFetch from "../../hooks/useFetch";
import Loading from "../../components/Common/Loading/Loading";
import TableLayer from "../../components/Common/TableLayer/TableLayer";
import { FaPlus } from "react-icons/fa";
import Table from "../../components/Common/Table/Table";
import { DateComponent } from "../../components/Common/Table/UtilityComponent";
import { TableData } from "../../type/TableDataType";
import { userType } from "../../type/UserType";
import { statusColorCheck } from "../../utils/statusColor";
import { UserCard } from "../../components/User/UserCard";
import UserCreate from "../../components/User/UserCreate";
import UserUpdate from "../../components/User/UserUpdate";
import UserView from "../../components/User/UserView";
import { DATA_CONTEXT } from "../../provider/DataProvider";

const UserManagePage = () => {
    const { userDataMange } = useContext(DATA_CONTEXT) || {};
    const [user, loading, refetch] = useFetch({
        api: "user/list"
    });

    if (loading) {
        return <Loading />;
    }

    const filterUser = (user as any).filter((item: any) => item._id !== userDataMange.user.id);

    const tableBody = (filterUser as userType[]).map((item) => {
        return {
            id: item._id,
            user: <UserCard image={item.imgURL} name={item.name} userName={item.email} />,
            date: <DateComponent date={item.updatedAt} />,
            role: (
                <div
                    className={`${statusColorCheck(
                        item.role
                    )}  p-2 px-4 rounded-md w-fit font-bold text-white capitalize`}
                >
                    {item.role === "admin" ? item.role : "investor"}
                </div>
            ),
            status: (
                <div
                    className={`${statusColorCheck(
                        item.status
                    )}   p-2 px-4 rounded-md w-fit font-bold text-white capitalize`}
                >
                    {item.status}
                </div>
            )
        };
    });

    const tableData = {
        columnItems: ["user", "Last Updated", "Role", "Status", "Action"],
        tableBody: tableBody,
        refetch: refetch,
        dataClass: "ReferenceRequest",
        database: "user"
    };

    return (
        <TableLayer>
            <HelmetConfiguration seoData={SeoContent["userManage"] || SeoContent.baseSeo} />
            <section className="container mx-auto py-10 ">
                <div className=" flex justify-end px-4">
                    <label
                        className="bg-dark dark:bg-white dark:text-dark text-white px-4 py-2 rounded-md flex items-center gap-x-2 cursor-pointer duration-300 hover:bg-dark/80 dark:hover:bg-white/80"
                        htmlFor="addUser"
                    >
                        <FaPlus /> Add User
                    </label>
                </div>

                <Table edit user action tableData={tableData as unknown as TableData} />

                <div>
                    {(user as userType[]).map((item) => (
                        <div key={item.userId}>
                            <UserView item={item} />
                            <UserUpdate item={item} refetch={[refetch] as any} />
                        </div>
                    ))}
                </div>

                <UserCreate refetch={[refetch] as any} />
            </section>
        </TableLayer>
    );
};

export default UserManagePage;
