import React, { useContext } from "react";
import { Outlet } from "react-router-dom";
import SideBar from "../components/SideBar/SideBar";
import Header from "../components/Header/Header";
import { SIDEBAR_CONTEXT } from "../provider/SideBarProvider";

const AppLayout = () => {
    const { sideBarOpen, setSideBarOpen } = useContext(SIDEBAR_CONTEXT) || {};
    const layoutClass = `w-full ${
        sideBarOpen ? "ml-[24rem] sm:ml-[10rem] md:ml-[16rem] lg:ml-[18rem] duration-300 " : "ml-[5rem] duration-300"
    }`;
    const mainClass = sideBarOpen ? "hidden sm:block  mt-[4rem] " : "mt-[4rem] ";

    return (
        <>
            <Header sideBarOpen={sideBarOpen} />

            <div className={`flex bg-primaryBG dark:bg-dark dark:text-white duration-300`}>
                <SideBar open={sideBarOpen} setOpen={setSideBarOpen} />

                <div className={layoutClass}>
                    <main className={mainClass + " min-h-[93.1vh]"}>
                        <Outlet />
                    </main>
                </div>
            </div>
        </>
    );
};

export default AppLayout;
