import React from "react";

const TableSearch = ({ updateFilter }: any) => {
    return (
        <div className="p-6 bg-white dark:bg-darkSecondary">
            <input
                onChange={updateFilter}
                type="text"
                placeholder="Search"
                className="input input-bordered border-2 focus:border-primary w-full max-w-xs focus:outline-none dark:bg-darkSecondary dark:border-primary/20 dark:focus:border-primary"
            />
        </div>
    );
};

export default TableSearch;
