import React, { useContext } from "react";
import { BsCircleFill } from "react-icons/bs";

import ActionMenu from "./ActionMenu";
import { FaPlus } from "react-icons/fa";
import { DATA_CONTEXT } from "../../../provider/DataProvider";
import { update } from "../../../hooks/update";
import { BiTrashAlt } from "react-icons/bi";
import { Delete } from "../../../hooks/delete";
import Swal from "sweetalert2";

const TableBody = ({
    data,
    refetch,
    lastItem,
    action,
    button,
    user,
    edit
}: {
    data: any;
    refetch: () => void;
    lastItem: boolean;
    action?: boolean;
    button?: boolean;
    user?: boolean;
    edit?: boolean;
}) => {
    const { userDataMange } = useContext(DATA_CONTEXT) || {};
    const userRole = userDataMange.user.role;

    const adminTableData = Object.keys(data).filter((key) => key !== "id" && key !== "action" && key !== "fileUrl");
    const userTableData = Object.keys(data).filter(
        (key) => key !== "id" && key !== "action" && key !== "fileUrl" && key !== "view"
    );
    const handleRestore = () => {
        console.log(data?.id);
        update({
            endPoint: "file",
            id: data?.id,
            data: { delete: "false" },
            message: "Restored!",
            refetch: [refetch]
        });
    };
    const handleDelete = () => {
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#00A76F",
            cancelButtonColor: "#D32F2F",
            confirmButtonText: "Yes, delete it!"
        }).then((result) => {
            if (result.isConfirmed) {
                Delete({
                    endPoint: "file/trash",
                    id: data?.id,
                    message: "File Deleted!",
                    refetch: refetch
                });
                Swal.fire("Permanently Deleted!", "Your file has been deleted.", "success");
            }
        });
    };

    const RestoreButton = () => {
        return (
            <td>
                <div className="px-4 w-fit flex gap-x-2">
                    <button
                        onClick={handleRestore}
                        className="bg-primary text-white px-4 py-2 rounded-md flex items-center gap-x-2 duration-300  "
                    >
                        <FaPlus /> Restore
                    </button>
                    <button
                        onClick={handleDelete}
                        className="bg-error text-white px-4 py-2 rounded-md flex items-center gap-x-2 duration-300  "
                    >
                        <BiTrashAlt /> Delete
                    </button>
                </div>
            </td>
        );
    };

    return (
        <tr className={` dark:text-white even:!bg-white dark:even:!bg-darkSecondary dark:border-white/5`}>
            <td></td>
            {(userRole === "admin" ? adminTableData : userTableData).map((item, index) => (
                <td key={index} className="!w-fit">
                    {data[item]}
                </td>
            ))}
            {action && (
                <td>
                    <ActionMenu
                        user={user}
                        fileUrl={data.fileUrl}
                        refetch={refetch}
                        id={data?.id}
                        lastItem={lastItem}
                        edit={edit}
                    />
                </td>
            )}
            {button && <RestoreButton />}
        </tr>
    );
};

export default TableBody;
