import React, { useContext, useRef } from "react";
import logo from "../../assets/images/logo/logo.png";
import CustomInputField from "../../components/Common/CustomInputField/CustomInputField";
import { useNavigate } from "react-router-dom";
import { DATA_CONTEXT } from "../../provider/DataProvider";
import HelmetConfiguration from "../../utils/helmetConfiguration";
import { SeoContent } from "../../content/seoContent";
import { DARKMODE_CONTEXT } from "../../provider/DarkModeProvider";

import { loginUser } from "../../hooks/login";
import LoginInput from "./LoginInput";
import { toast } from "react-toastify";

const LoginPage = () => {
    const { userDataMange } = useContext(DATA_CONTEXT) || {};
    const { darkMode, setDarkMode } = useContext(DARKMODE_CONTEXT) || {};
    const navigate = useNavigate();
    const formRef = useRef<HTMLFormElement | null>(null);

    const handleSwitch = () => {
        setDarkMode(!darkMode);
    };

    interface FormData {
        // Define your form data fields here
        email: string;
        password: string;
    }
    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (formRef.current) {
            const data: FormData = {
                email: formRef.current.email.value,
                password: formRef.current.password.value
            };

            loginUser({
                data: data,
                refetch: userDataMange.userRefetch,
                navigate: navigate,
                darkMOde: darkMode
            });
        }
    };

    return (
        <div className=" text-white relative backgroundPhoto">
            <HelmetConfiguration seoData={SeoContent["loginSeo"] || SeoContent.baseSeo} />
            <div className="w-[20rem] sm:w-[25rem] mx-auto min-h-screen flex flex-col justify-center gap-y-4 z-[9999]">
                <h2 className="w-contain text-4xl text-center w-full font-semibold  text-white">Investor Login</h2>

                <form ref={formRef} onSubmit={handleSubmit} className="flex flex-col gap-y-4  pt-4">
                    <LoginInput name="email" title="Investor Email" type="email" required />
                    <LoginInput name="password" title="Password" type="password" required />
                    <button type="submit" className="bg-secondary text-white uppercase font-medium py-4 mt-4">
                        login
                    </button>

                    <button
                        onClick={() => toast.info("Contact Admin!")}
                        type="button"
                        className="text-sm text-white/80"
                    >
                        Forgot Password
                    </button>
                </form>
            </div>
        </div>
    );
};

export default LoginPage;
