import React, { useContext } from "react";
import { DATA_CONTEXT } from "../../../provider/DataProvider";

const TableHead = ({ columnItems }: { columnItems: string[] }) => {
    const { userDataMange } = useContext(DATA_CONTEXT) || {};
    const userRole = userDataMange.user.role;
    const userItems = columnItems.filter((item) => item != "No. of Download");

    return (
        <thead className="dark:text-white text-black text-sm bg-[#F4F6F8] h-16 dark:bg-darkSecondary">
            <tr className="border-0 bg-primary/10 capitalize">
                <th> </th>
                {(userRole === "admin" ? columnItems : userItems).map((item, index) => (
                    <th key={index}>{item}</th>
                ))}
            </tr>
        </thead>
    );
};

export default TableHead;
