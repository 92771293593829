import React, { useContext } from "react";

import Toggler from "../Toggler/Toggler";
import User from "./User";
import { DARKMODE_CONTEXT } from "../../provider/DarkModeProvider";

const Header = ({ sideBarOpen }: any) => {
    const { darkMode, setDarkMode } = useContext(DARKMODE_CONTEXT) || {};
    const handleSwitch = () => {
        setDarkMode(!darkMode);
    };

    const layoutClass = `${
        sideBarOpen
            ? "pl-[1rem] sm:pl-[10rem] md:pl-[12rem] lg:pl-[14rem]  duration-300 w-full"
            : "pl-[4rem] w-full duration-300"
    }`;
    return (
        <div className={`${layoutClass} py-5 px-4 xl:px-16 fixed top-0  shadowBottom bg-white dark:bg-dark  z-[500]`}>
            <div className="flex justify-end items-center gap-x-2 sm:gap-x-4 xl:gap-x-4">
                <Toggler darkMode={darkMode} handleSwitch={handleSwitch} />
                <User />
            </div>
        </div>
    );
};

export default Header;
