import { toast } from "react-toastify";
import { getToken } from "../utils/token";
import axios from "axios";

interface CreateParams {
    endPoint: string;
    data: any;
    refetch?: any[];
    reset?: () => void;
    message: string;
    loading?: (isLoading: boolean) => void;
}

export const create = async ({ endPoint, data, refetch, reset, message, loading }: CreateParams) => {
    if (loading) {
        loading(true);
    }

    const headers: Record<string, string> = {
        Authorization: "Bearer " + getToken()
    };

    await axios
        .post(`${process.env.REACT_APP_PUBLIC_SERVER}/${endPoint}`, data, { headers })
        .then((response) => {
            toast.success(message, {
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });

            if (reset && response.status === 200) {
                reset();
            }
            if (refetch && response.status === 200) {
                refetch.forEach((item: () => void) => item());
            }

            if (loading && response.status === 200) {
                loading(false);
            }
            // Handle success
        })
        .catch((error) => {
            toast.error((error as any).message, {
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
            if (loading) {
                loading(false);
            }
        });
};
