export const dateFormatter = (date: any) => {
    const currentDate = new Date(date);
    const currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || "UTC";
    const formattedDate = currentDate.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "long",
        year: "numeric",
        timeZone: currentTimeZone
    });

    return formattedDate;
};