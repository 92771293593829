import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "sweetalert2/dist/sweetalert2.min.css";

import RequireAuth from "./auth/RequireAuth";
import LoggedInAuth from "./auth/LoggedInAuth";

import AppLayout from "./layout/AppLayout";
import ProfilePage from "./page/ProfilePage/ProfilePage";

import LoginPage from "./page/LoginPage/LoginPage";

import ErrorPage from "./page/ErrorPage/ErrorPage";

import UserManagePage from "./page/UserManagePage/UserManagePage";
import MonthlyNewsLetterPage from "./page/MonthlyNewsLetterPage/MonthlyNewsLetterPage";
import MarketingPresentationsPage from "./page/MarketingPresentationsPage/MarketingPresentationsPage";
import OfferingDocumentsPage from "./page/OfferingDocumentsPage/OfferingDocumentsPage";
import DueDiligenceDocsPage from "./page/DueDiligenceDocsPage/DueDiligenceDocsPage";
import FundConstitutionalDocsPage from "./page/FundConstitutionalDocsPage/FundConstitutionalDocsPage";
import TrashBinPage from "./page/TrashBinPage/TrashBinPage";

function App() {
    return (
        <main>
            <Routes>
                <Route
                    path="/"
                    element={
                        <RequireAuth>
                            <AppLayout />
                        </RequireAuth>
                    }
                >
                    <Route index element={<MonthlyNewsLetterPage />} />
                    <Route path="marketing-presentations" element={<MarketingPresentationsPage />} />
                    <Route path="offering-documents" element={<OfferingDocumentsPage />} />
                    <Route path="due-diligence-docs" element={<DueDiligenceDocsPage />} />
                    <Route path="fund-constitutional-docs" element={<FundConstitutionalDocsPage />} />
                    <Route path="trash" element={<TrashBinPage />} />
                    <Route path="profile" element={<ProfilePage />} />
                    <Route path="userManage" element={<UserManagePage />} />
                    <Route path="*" element={<ErrorPage />} />
                </Route>

                <Route path="*" element={<ErrorPage />} />
                <Route
                    path="/login"
                    element={
                        <LoggedInAuth>
                            <LoginPage />
                        </LoggedInAuth>
                    }
                />
            </Routes>
            <ToastContainer />
        </main>
    );
}

export default App;
