export const statusColorCheck = (status: string): string => {
    if (status === "all") {
        return "bg-dark dark:bg-white !text-white dark:!text-dark";
    }
    else if (status === "feature") {
        return "bg-orange-500";
    }
    else if (status === "most view") {
        return "bg-sky-500";
    }
    else if (status === "latest") {
        return "bg-green-500";
    }
    else if (status === "active") {
        return "bg-green-500";
    }
    else if (status === "inactive") {
        return "bg-red-500";
    }
    else if (status === "admin") {
        return "bg-orange-500";
    }
    else if (status === "moderator") {
        return "bg-blue-500";
    }
    else if (status === "viewer") {
        return "bg-purple-500";
    }
    else {
        return "bg-red-500";
    }
};
