import React from "react";
import { BsCalendar2Check } from "react-icons/bs";
import { dateFormatter } from "../../utils/dateFormat";
import ReactPlayer from "react-player";
import { userType } from "../../type/UserType";

const UserView = ({ item }: { item: userType }) => {
    const { _id, imgURL, name, email, role, status, createdAt, updatedAt } = item;

    return (
        <>
            {/* Put this part before </body> tag */}
            <input type="checkbox" id={_id + "view"} className="modal-toggle" />
            <div className="modal ">
                <div className="modal-box w-11/12 max-w-4xl rounded dark:bg-darkBG">
                    <div>
                        <img src={imgURL} alt="" className="h-[20rem] lg:h-[25rem] w-full object-cover" />
                    </div>

                    <div className="my-4">
                        <h1 className="text-lg font-bold  pb-2 ml-2">User Created</h1>
                        <div className="flex items-center gap-x-2 bg-[#EBEBEB] p-2 rounded-full px-4 dark:text-black w-fit">
                            <span className="text-lg">
                                <BsCalendar2Check />
                            </span>
                            {dateFormatter(createdAt)}
                        </div>
                    </div>
                    <div className="my-4">
                        <h1 className="text-lg font-bold  pb-2 ml-2">User Updated</h1>
                        <div className="flex items-center gap-x-2 bg-[#EBEBEB] p-2 rounded-full px-4 dark:text-black w-fit">
                            <span className="text-lg">
                                <BsCalendar2Check />
                            </span>
                            {dateFormatter(updatedAt)}
                        </div>
                    </div>

                    <div className="flex flex-col gap-y-4 pt-4">
                        <div>
                            <h1 className="font-bold text-2xl">{name}</h1>
                            <h1 className="font-semibold text-lg">Email: {email}</h1>
                        </div>
                    </div>

                    <div className="flex justify-end items-center gap-4 mt-4">
                        <label htmlFor={_id + "view"} className="btn bg-error text-white hover:bg-error border-0">
                            Close
                        </label>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UserView;
