import React, { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import person from "../../assets/images/header/person.jpg";
import { DATA_CONTEXT } from "../../provider/DataProvider";

const User = () => {
    const { userDataMange } = useContext(DATA_CONTEXT) || {};
    const { user } = userDataMange;

    return (
        <div className="dropdown dropdown-end">
            <label tabIndex={0} className="cursor-pointer">
                <div className="w-10 h-10 rounded-full overflow-hidden">
                    <img src={user.image ? user.image : person} alt={person} className="h-full w-full object-cover" />
                </div>
            </label>
            <ul
                tabIndex={0}
                className="mt-3 z-[1] rounded-md  shadow-md  dropdown-content bg-base-100   dark:bg-darkSecondary dark:text-white w-52"
            >
                <li className="border-b border-dashed	 p-4">
                    <h1 className="font-bold capitalize">{user.name}</h1>
                </li>
                <li className="px-2 pt-1 w-full flex justify-start items-center">
                    <NavLink
                        to="/profile"
                        className="px-2 py-1 duration-300 hover:bg-gray-200 w-full hover:rounded-lg dark:hover:text-dark"
                    >
                        Profile
                    </NavLink>
                </li>
                {user.role === "admin" && (
                    <li className="px-2 py-1 w-full flex justify-start items-center border-b border-dashed	">
                        <NavLink
                            to="/userManage"
                            className="px-2 py-1 duration-300 hover:bg-gray-200 dark:hover:text-dark w-full hover:rounded-lg"
                        >
                            User Mange
                        </NavLink>
                    </li>
                )}
            </ul>
        </div>
    );
};

export default User;
