import React, { useState } from "react";
import TableHead from "./TableHead";
import TableBody from "./TableBody";
import TableSearch from "../TableSearch/TableSearch";
import { TableData } from "../../../type/TableDataType";
import Pagination from "../Pagination/Pagination";

const Table = ({
    tableData,
    action,
    user,
    button,
    edit
}: {
    tableData: TableData;
    user?: boolean;
    action?: boolean;
    button?: boolean;
    edit?: boolean;
}) => {
    const [page, setPage] = useState<number>(0);
    const itemCount: number = 5;
    const { columnItems, tableBody, refetch } = tableData;
    return (
        <div className="relative overflow-x-auto !!!overflow-y-hidden z-0 shadow-lg  my-10 min-h-[78.6vh] bg-white dark:bg-darkSecondary xl:rounded-lg flex flex-col justify-between ">
            <div>
                <TableSearch updateFilter={tableData.updateFilter} />

                <table className="table ">
                    <TableHead columnItems={columnItems} />
                    {tableBody.length > 0 && (
                        <tbody>
                            {tableBody
                                .slice(page * itemCount, page * itemCount + itemCount)
                                .map((item: { attributes: any }, index: any) => (
                                    <TableBody
                                        key={index}
                                        data={item}
                                        action={action}
                                        button={button}
                                        lastItem={tableBody.length === index + 1}
                                        refetch={refetch}
                                        user={user}
                                        edit={edit}
                                    />
                                ))}
                        </tbody>
                    )}
                </table>
                {tableBody.length === 0 && <h1 className="text-center font-bold py-4">No Data Found!</h1>}
            </div>
            <Pagination itemCount={itemCount} page={page} setPage={setPage} dataLength={tableData.tableBody.length} />
        </div>
    );
};

export default Table;
