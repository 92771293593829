export const convertImage = async ({ image, setLoading }: { image: File, setLoading: (isLoading: boolean) => void }) => {
    if (setLoading) {
        setLoading(true);
    }
    const formData = new FormData();
    formData.append("file", image);
    formData.append("upload_preset", "Article");
    try {
        const response = await fetch(
            "https://api.cloudinary.com/v1_1/ddh3v3lua/image/upload",
            {
                method: "POST",
                body: formData,
            }
        );
        const data = await response.json();
        return data.url;
    } catch (error) {
    } finally {
        if (setLoading) {
            setLoading(false);
        }
    }
};
