import React from "react";
import { FaMoon, FaSun } from "react-icons/fa";

const Toggler = ({ darkMode, handleSwitch }: any) => {
    return (
        <button
            onClick={handleSwitch}
            className={` text-2xl w-10 h-10 flex justify-center items-center rounded-full bg-primary/20 hover:bg-primary/20 duration-300 z-[999] ${
                darkMode ? "!text-white" : "text-primary"
            } cursor-pointer`}
        >
            {!darkMode ? <FaMoon /> : <FaSun />}
        </button>
    );
};

export default Toggler;
