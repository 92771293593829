import React from "react";
import ProfileImage from "../ProfileImage/ProfileImage";
import ProfileForm from "../ProfileForm/ProfileForm";

const General = () => {
    return (
        <section className="grid grid-cols-1 lg:grid-cols-3 gap-y-4 lg:gap-x-4">
            <ProfileImage />
            <ProfileForm />
        </section>
    );
};

export default General;
