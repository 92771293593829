import React, { createContext } from "react";
import useSession from "../hooks/useSession";
import { ChildrenProps } from "../type/Types";
import Loading from "../components/Common/Loading/Loading";

export const DATA_CONTEXT = createContext<any | undefined>(undefined);

const DataProvider = ({ children }: ChildrenProps) => {
    const [user, loading, userRefetch] = useSession();
    if (loading) {
        return <Loading />;
    }
    const userDataMange = { user, loading, userRefetch };
    const value = {
        userDataMange
    };

    return <DATA_CONTEXT.Provider value={value}>{children}</DATA_CONTEXT.Provider>;
};

export default DataProvider;
