import React, { useState, useRef } from "react";

import CustomInputField from "../Common/CustomInputField/CustomInputField";
import CategorySelect from "../Common/CategorySelect/CategorySelect";
import { toast } from "react-toastify";
import { create } from "../../hooks/create";
import ImageUpload from "../Common/ModalComponent/ImageUpload";

const UserCreate = ({ refetch }: { refetch: any }) => {
    const [image, setImage] = useState<null | string>(null);
    const [role, setRole] = useState<string[]>(["viewer"]);
    const [status, setStatus] = useState<string[]>(["active"]);

    const [loading, setLoading] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const closeModal = () => {
        const form = formRef.current;
        const label = document.querySelector<HTMLLabelElement>('[for="addUser"]');
        if (form && label) {
            form.reset();
            setImage(null);
            setStatus([]);
            setRole([]);
            label.click();
        }
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();

        if (image) {
            if (event.target.password.value === event.target.confirmPassword.value) {
                const data = {
                    imgURL: image,
                    name: event.target.name.value,
                    email: event.target.email.value,
                    password: event.target.password.value,
                    role: role[0],
                    status: status[0]
                };

                create({
                    endPoint: "user/create",
                    data: data,
                    refetch: refetch,
                    reset: closeModal,
                    message: "New User Created"
                });
            } else {
                toast.info("Password and confirm password did't matched!");
            }
        } else {
            toast.info("Please Add Image!");
        }
    };

    return (
        <>
            {/* Put this part before </body> tag */}
            <input type="checkbox" id="addUser" className="modal-toggle" />

            <form ref={formRef} onSubmit={handleSubmit} className="modal ">
                <div className="modal-box w-11/12 max-w-4xl rounded dark:bg-darkBG">
                    <ImageUpload
                        image={image}
                        setImage={setImage}
                        fileInputRef={fileInputRef}
                        loading={loading}
                        setLoading={setLoading}
                    />
                    <div className="mt-4 flex flex-col gap-y-2">
                        <CustomInputField name="name" title="Name" type="text" required />
                        <CustomInputField name="email" title="User Email" type="email" required />
                        <CustomInputField name="password" title="Password" type="password" required />
                        <CustomInputField name="confirmPassword" title="Confirm Password" type="password" required />

                        <CategorySelect title="Role" select={role} update={setRole} categories={["admin", "user"]} />
                        <CategorySelect
                            title="Status"
                            select={status}
                            update={setStatus}
                            categories={["active", "inactive"]}
                        />
                    </div>

                    <div className="flex justify-end items-center gap-4 mt-4">
                        <button
                            type="submit"
                            disabled={loading}
                            className={`${
                                loading && "opacity-50 cursor-not-allowed"
                            } btn bg-success text-white hover:bg-success border-0`}
                        >
                            Submit
                        </button>
                        <button
                            type="button"
                            disabled={loading}
                            onClick={closeModal}
                            className={`${
                                loading && "opacity-50 cursor-not-allowed"
                            } btn bg-error text-white hover:bg-error border-0`}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
};
export default UserCreate;
