import React, { createContext, useState, useEffect } from "react";
import { ChildrenProps } from "../type/Types";

export const DARKMODE_CONTEXT = createContext<any | undefined>(undefined);

const DarkModeProvider = ({ children }: ChildrenProps) => {
    const [darkMode, setDarkMode] = useState(localStorage.getItem("darkMode") === "true");
    useEffect(() => {
        const isDarkMode = localStorage.getItem("darkMode") === "true";
        setDarkMode(isDarkMode);
    }, [setDarkMode]);

    useEffect(() => {
        if (darkMode) {
            document.documentElement.classList.remove("light");
            document.documentElement.classList.add("dark");
        } else {
            document.documentElement.classList.remove("dark");
            document.documentElement.classList.add("light");
        }
        localStorage.setItem("darkMode", darkMode.toString());
    }, [darkMode]);
    const value: any = { darkMode, setDarkMode };

    return <DARKMODE_CONTEXT.Provider value={value}>{children}</DARKMODE_CONTEXT.Provider>;
};

export default DarkModeProvider;
