import React, { useState, useRef, useContext } from "react";
import CategorySelect from "../Common/CategorySelect/CategorySelect";
import { toast } from "react-toastify";
import { categories } from "../../content/categoriesContent";
import { create } from "../../hooks/create";

import InvestorSelect from "../Common/CategorySelect/InvestorSelect";
import { DATA_CONTEXT } from "../../provider/DataProvider";

const FileUpload = ({ refetch, userList }: { refetch: any; userList: any }) => {
    const { userDataMange } = useContext(DATA_CONTEXT);
    const currentUser = userDataMange?.user;

    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
    const [selectedInvestors, setSelectedInvestors] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);

    const closeModal = () => {
        const form = formRef.current;
        const label = document.querySelector<HTMLLabelElement>('[for="fileUpload"]');
        if (form && label) {
            form.reset();
            setSelectedCategories([]);
            setSelectedInvestors([]);
            label.click();
        }
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if (selectedCategories.length > 0) {
            const formData = new FormData();
            formData.append("files", event.target.files.files[0]);
            formData.append("fileCategory", selectedCategories[0]);
            formData.append("owner", currentUser.id);
            selectedInvestors.forEach((sharedUserId, index) => {
                if (sharedUserId !== "all") formData.append(`sharedTo[${index}]`, sharedUserId);
            });

            await create({
                endPoint: "file/upload",
                data: formData,
                refetch: refetch,
                reset: closeModal,
                message: "New File Created",
                loading: setLoading
            });
        } else {
            toast.info("Select at-least one category!");
        }
    };

    return (
        <>
            {/* Put this part before </body> tag */}
            <input type="checkbox" id="fileUpload" className="modal-toggle" />

            <form ref={formRef} onSubmit={handleSubmit} className="modal ">
                <div className="modal-box w-11/12 max-w-4xl rounded dark:bg-darkBG">
                    <div>
                        <label className="form-control w-full max-w-xs ">
                            <div className="label">
                                <span className="label-text dark:text-white">Pick a file</span>
                            </div>
                            <input
                                name="files"
                                required
                                type="file"
                                className="file-input file-input-bordered w-full max-w-xs dark:bg-dark"
                                accept=".pdf"
                            />
                        </label>
                    </div>
                    <div className="mt-4 flex flex-col gap-y-2">
                        <CategorySelect
                            title="Choose Categories"
                            select={selectedCategories}
                            update={setSelectedCategories}
                            categories={categories}
                        />
                        <InvestorSelect
                            title="Choose Investors"
                            select={selectedInvestors}
                            update={setSelectedInvestors}
                            investors={userList}
                        />
                    </div>

                    <div className="flex justify-end items-center gap-4 mt-4">
                        <button
                            type="submit"
                            disabled={loading}
                            className={`${
                                loading && "opacity-50 cursor-not-allowed"
                            } btn bg-success text-white hover:bg-success border-0`}
                        >
                            Submit
                        </button>
                        <button
                            type="button"
                            disabled={loading}
                            onClick={closeModal}
                            className={`${
                                loading && "opacity-50 cursor-not-allowed"
                            } btn bg-error text-white hover:bg-error border-0`}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
};
export default FileUpload;
