import { IoNewspaperSharp } from "react-icons/io5";
import { GrDocumentStore } from "react-icons/gr";

import { Menu } from "../../type/Types";

export const menus: Menu[] = [
    {
        name: "overview"
    },
    {
        name: "Monthly Newsletter",
        link: "/",
        icon: IoNewspaperSharp
    },
    {
        name: "Marketing Presentations",
        link: "/marketing-presentations",
        icon: IoNewspaperSharp
    },
    {
        name: "Offering Documents",
        link: "/offering-documents",
        icon: IoNewspaperSharp
    },
    {
        name: "Due Diligence Docs",
        link: "/due-diligence-docs",
        icon: IoNewspaperSharp
    },
    {
        name: "Fund Constitutional Docs",
        link: "/fund-constitutional-docs",
        icon: IoNewspaperSharp
    }
];
