/* eslint-disable no-empty-pattern */
import React, { useContext } from "react";
import logo from "../../assets/images/logo/logo.png";
import logoBlack from "../../assets/images/logo/logoBlack.png";

import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

import { NavLink, useNavigate } from "react-router-dom";
import { menus as originalMenus } from "./constant";
import { Menu } from "../../type/Types";
import { IoLogOut } from "react-icons/io5";
import { DATA_CONTEXT } from "../../provider/DataProvider";
import { FaTrash } from "react-icons/fa";

const menus: Menu[] = originalMenus;

const SideBar = ({ open, setOpen }: any) => {
    const { userDataMange } = useContext(DATA_CONTEXT) || {};
    const { userRefetch } = userDataMange;
    const userRole = userDataMange.user.role;
    const navigate = useNavigate();
    const handleLogout = () => {
        localStorage.removeItem("dullSquare");
        userRefetch();
        navigate("/login");
    };
    return (
        <section
            className={`bg-white dark:bg-dark min-h-screen fixed z-[999] ${
                open ? "w-[100vw] sm:w-[10rem] md:w-[16rem] lg:w-[18rem]" : "w-[4rem] xl:w-[5rem] border-r"
            } duration-300 text-dark px-4 border-dashed  border-[#FFFFFF1E] dark:text-white sm:border-r flex flex-col justify-between`}
        >
            <button
                className={`bg-primaryBG dark:bg-darkBG border border-dark:[#FFFFFF1E] duration-300 rounded-full p-1 text-bold absolute sm:top-[1.8rem] top-[3.5rem] xl:top-7 ${
                    open ? "right-0" : "right-[-1rem]"
                } sm:right-[-.8rem] z-[999]`}
                onClick={() => setOpen(!open)}
            >
                {open ? <FiChevronLeft /> : <FiChevronRight />}
            </button>

            <div>
                <div className="mt-6 xl:my-10">
                    <div className="flex justify-center items-center">
                        <img src={logo} alt="" className={`${open ? "w-[12rem]" : "w-[4rem]"} hidden dark:block`} />
                        <img src={logoBlack} alt="" className={`${open ? "w-[12rem]" : "w-[4rem]"} dark:hidden`} />
                    </div>
                </div>

                <div className="mt-4 flex flex-col gap-2 relative capitalize">
                    {menus.map((menu, i) => (
                        <div key={i}>
                            {menu.link ? (
                                <NavLink
                                    to={menu?.link}
                                    className={`   group flex items-center text-sm  gap-3.5  p-3 hover:bg-primary/80 rounded-md disabled ${
                                        open && " pl-4"
                                    } hover:text-white`}
                                >
                                    <div className={`${!open ? "ml-[-3px] xl:ml-[0.3rem]" : ""}`}>
                                        {menu?.icon && React.createElement(menu.icon, { size: 20 })}
                                    </div>
                                    {
                                        <h2
                                            className={`whitespace-pre duration-300 ${
                                                !open ? "opacity-0 overflow-hidden" : ""
                                            }`}
                                        >
                                            {menu?.name}
                                        </h2>
                                    }
                                </NavLink>
                            ) : (
                                <h2
                                    className={`whitespace-pre duration-300 uppercase font-semibold pb-2 text-sm ${
                                        !open ? "hidden" : ""
                                    }`}
                                >
                                    {menu?.name}
                                </h2>
                            )}
                        </div>
                    ))}

                    <h2
                        className={`pt-6 whitespace-pre duration-300 uppercase font-semibold  text-sm ${
                            !open ? "hidden" : ""
                        }`}
                    >
                        user
                    </h2>
                    {userRole === "admin" && (
                        <NavLink
                            to="/trash"
                            className={`group flex items-center text-sm  gap-3.5  p-3 hover:bg-primary/80 rounded-md disabled pl-4 hover:text-white  ${
                                open && " pl-4"
                            } font-bold`}
                        >
                            <div className={`${!open ? "ml-[-3px] xl:ml-[0.3rem]" : ""} text-lg`}>
                                <FaTrash />
                            </div>
                            {
                                <h2
                                    className={`whitespace-pre duration-300 ${
                                        !open ? "opacity-0 overflow-hidden" : ""
                                    }`}
                                >
                                    Trash Bin
                                </h2>
                            }
                        </NavLink>
                    )}
                    <button
                        onClick={handleLogout}
                        className={`group flex items-center text-sm  gap-3.5  p-3 hover:bg-error/95 rounded-md disabled pl-4 hover:text-white text-error ${
                            open && " pl-4"
                        } font-bold`}
                    >
                        <div className={`${!open ? "ml-[-3px] xl:ml-[0.3rem]" : ""} text-2xl`}>
                            <IoLogOut />
                        </div>
                        {
                            <h2 className={`whitespace-pre duration-300 ${!open ? "opacity-0 overflow-hidden" : ""}`}>
                                Logout
                            </h2>
                        }
                    </button>
                </div>
            </div>
        </section>
    );
};

export default SideBar;
