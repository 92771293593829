import React from "react";
import HelmetConfiguration from "../../utils/helmetConfiguration";
import { SeoContent } from "../../content/seoContent";
import useFetch from "../../hooks/useFetch";
import Loading from "../../components/Common/Loading/Loading";
import FileManageTable from "../../components/FileManageTable/FileManageTable";
import FileUpload from "../../components/FileManage/FileUpload";

const DueDiligenceDocsPage = () => {
    const [file, loading, refetch] = useFetch({
        api: "file/search",
        query: "category=Due Diligence Docs"
    });
    const [userList, userLoading] = useFetch({
        api: "user/list"
    });

    if (loading || userLoading) {
        return <Loading />;
    }

    const users = (userList as any).map((item: any) => ({ email: item.email, id: item._id }));

    return (
        <section>
            <HelmetConfiguration seoData={SeoContent["dueSeo"] || SeoContent.baseSeo} />
            <FileManageTable file={file} refetch={refetch} />
            <FileUpload userList={users} refetch={[refetch] as any} />
        </section>
    );
};

export default DueDiligenceDocsPage;
