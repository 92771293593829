import React, { useState, useRef } from "react";

import CustomInputField from "../Common/CustomInputField/CustomInputField";
import CategorySelect from "../Common/CategorySelect/CategorySelect";
import { toast } from "react-toastify";
import { create } from "../../hooks/create";
import ImageUpload from "../Common/ModalComponent/ImageUpload";
import { userType } from "../../type/UserType";
import { update } from "../../hooks/update";

const UserUpdate = ({ item, refetch }: { item: userType; refetch: any }) => {
    const { _id, name, email, role: userRole, status: userStatus, imgURL } = item;
    const [changePassword, setChangePassword] = useState(false);
    const [image, setImage] = useState<null | string>(imgURL);
    const [role, setRole] = useState<string[]>([userRole]);
    const [status, setStatus] = useState<string[]>([userStatus]);

    const [loading, setLoading] = useState<boolean>(false);
    const formRef = useRef<HTMLFormElement>(null);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const closeModal = () => {
        const form = formRef.current;
        const label = document.querySelector<HTMLLabelElement>(`[for="${_id}update"]`);
        if (form && label) {
            label.click();
        }
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        let data: any;

        if (changePassword) {
            if (event.target.password.value === event.target.confirmPassword.value) {
                data = {
                    imgURL: image,
                    name: event.target.name.value,
                    userName: event.target.userName.value,
                    password: event.target.password.value,
                    role: role[0],
                    status: status[0]
                };
            } else {
                toast.info("Password and confirm password did't matched!");
            }
        } else {
            data = {
                imgURL: image,
                name: event.target.name.value,
                email: event.target.email.value,
                role: role[0],
                status: status[0]
            };
        }

        update({
            endPoint: "user/list",
            id: _id,
            data: data,
            refetch: refetch,
            reset: closeModal,
            message: "User Updated"
        });
    };

    return (
        <>
            {/* Put this part before </body> tag */}
            <input type="checkbox" id={_id + "update"} className="modal-toggle" />

            <form ref={formRef} onSubmit={handleSubmit} className="modal ">
                <div className="modal-box w-11/12 max-w-4xl rounded dark:bg-darkBG">
                    <ImageUpload
                        image={image}
                        setImage={setImage}
                        fileInputRef={fileInputRef}
                        loading={loading}
                        setLoading={setLoading}
                    />
                    <div className="mt-4 flex flex-col gap-y-2">
                        <CustomInputField name="name" title="Name" type="text" value={name} required />
                        <CustomInputField name="email" title="User Email" value={email} type="text" required />

                        <CategorySelect title="Role" select={role} update={setRole} categories={["admin", "user"]} />
                        <CategorySelect
                            title="Status"
                            select={status}
                            update={setStatus}
                            categories={["active", "inactive"]}
                        />
                        <div>
                            <button
                                type="button"
                                onClick={() => setChangePassword(!changePassword)}
                                className="text-base font-semibold "
                            >
                                {changePassword ? (
                                    <span className="text-error">Don't Change Password!</span>
                                ) : (
                                    <span className="text-primary">Change Password?</span>
                                )}
                            </button>
                        </div>

                        {changePassword && (
                            <div className="flex flex-col gap-y-3">
                                <CustomInputField name="password" title="Password" type="password" required />
                                <CustomInputField
                                    name="confirmPassword"
                                    title="Confirm Password"
                                    type="password"
                                    required
                                />
                            </div>
                        )}
                    </div>

                    <div className="flex justify-end items-center gap-4 mt-4">
                        <button
                            type="submit"
                            disabled={loading}
                            className={`${
                                loading && "opacity-50 cursor-not-allowed"
                            } btn bg-success text-white hover:bg-success border-0`}
                        >
                            Submit
                        </button>
                        <button
                            type="button"
                            disabled={loading}
                            onClick={closeModal}
                            className={`${
                                loading && "opacity-50 cursor-not-allowed"
                            } btn bg-error text-white hover:bg-error border-0`}
                        >
                            Close
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
};
export default UserUpdate;
